<template>
  <div v-if="!loading">
    <v-container
      style="
        padding: 0 0 20px 0;
        margin: auto;
        border-radius: 5px;
        background: white;
      "
    >
      <v-form
        ref="entryForm"
        @submit.prevent="submitHeader()"
        lazy-validation
        :disabled="loading"
      >
        <v-card
          tile
          flat
          style="
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            position: sticky;
            top: 60px;
            background: white;
            z-index: 5;
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 10px;
            "
          >
            <v-toolbar-title class="overline" style="position: relative">
              <p
                :style="`font-size:${
                  wWidth > 780 ? '20px' : '3vw'
                }; margin: 0;`"
              >
                Detail Realisasi Anggaran Biaya
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDetail"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </p>
            </v-toolbar-title>
          </div>
          <div v-if="detail !== null">
            <div v-if="!isEdit" class="actionButton">
              <div v-if="detail.status.id === 0">
                <v-btn
                  @click="submitRAB"
                  rounded
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-2"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Submit
                </v-btn>
              </div>
              <div v-if="detail.status.id === 2">
                <v-btn
                  @click="takeRAB"
                  rounded
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-2"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Take
                </v-btn>
              </div>
              <div v-if="detail.status.id === 3">
                <v-btn
                  @click="doneRAB"
                  rounded
                  elevation="0"
                  color="success"
                  class="white--text font-weight-bold ma-2"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Done
                </v-btn>
              </div>
              <div v-if="detail.status.id === 1">
                <v-btn
                  @click="confirmRAB"
                  rounded
                  elevation="0"
                  color="success"
                  class="white--text font-weight-bold ma-2"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Approve
                </v-btn>
              </div>
              <!-- Cancel button -->

              <!-- <div>
                <v-dialog
                  v-if="
                    detail.status.id === 0 ||
                      detail.status.id === 2 ||
                      detail.status.id === 3
                  "
                  v-model="cancelDialog"
                  width="500"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      type="button"
                      rounded
                      elevation="0"
                      color="red"
                      class="white--text font-weight-bold ma-2"
                      style="font-size:12px;"
                      :loading="loading"
                    >
                      Cancel
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Form Pembatalan
                    </v-card-title>

                    <v-card-text class="d-flex justify-center align-center">
                      <v-col
                        md="12"
                        style="padding: 0; position:relative; top:20px;"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                            >
                              Alasan pembatalan
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-textarea v-model="form.cancel_reason" outlined />
                          </v-col>
                        </div>
                      </v-col>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        color="error"
                        text
                        outlined
                        @click="cancelDialog = false"
                        :loading="loading"
                      >
                        Tutup
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="cancelRAB"
                        color="primary"
                        type="button"
                        text
                        outlined
                        :loading="loading"
                      >
                        Batalkan pengajuan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div> -->
              <div v-if="detail.status.id === 5 || detail.status.id === 6">
                <v-btn
                  @click="setToDraftRAB"
                  rounded
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-2"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Set to Draft
                </v-btn>
              </div>
              <div
                v-if="
                  detail.status.id === 0 ||
                  detail.status.id === 2 ||
                  detail.status.id === 3
                "
              >
                <v-btn
                  @click="cancelRAB"
                  rounded
                  elevation="0"
                  color="error"
                  class="white--text font-weight-bold ma-2"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Cancel
                </v-btn>
              </div>

              <v-btn
                v-if="detail.status.id === 1"
                @click="rejectRAB"
                type="button"
                rounded
                elevation="0"
                color="red"
                class="white--text font-weight-bold ma-2"
                style="font-size: 12px"
                :loading="loading"
              >
                reject
              </v-btn>
              <v-btn
                type="button"
                rounded
                elevation="0"
                color="black"
                class="black--text font-weight-bold ma-2"
                style="font-size: 12px"
                @click.prevent="close"
                :loading="loading"
                outlined
              >
                <v-icon small>mdi-chevron-left</v-icon>
                Kembali
              </v-btn>
            </div>
          </div>
          <div
            v-if="detail !== null"
            class="d-flex justify-center align-center"
            :style="`position:absolute; right:0; top:52px; ${statusColor(
              detail.status.id
            )} padding: 5px 30px; min-width: 200px;`"
          >
            <p
              style="
                margin: 0;
                font-size: 16px;
                font-weight: bolder;
                text-transform: uppercase;
              "
            >
              {{ detail.status.name }}
            </p>
          </div>
        </v-card>

        <v-card v-if="detail !== null" elevation="0">
          <v-toolbar-title style="padding: 0; text-align: center; height: 30px">
            <!-- <p
              v-if="detail.budget_no !== null"
              style="font-size: 20px; margin: 0; font-weight: bold; color:indigo;position:relative;"
            >
              {{ detail.budget_no }}
            </p> -->

            <div
              class="d-flex justify-start align-center"
              style="position: absolute; right: 0; left: 0; top: -20px"
            >
              <p style="font-size: 12px; margin: 0 15px">
                Tgl. Buat :
                {{ detail.created_at }}/{{ detail.created_by_name }}
              </p>
              <p style="font-size: 12px; margin: 0 15px">
                Tgl. Submit :
                {{ detail.submited_at }}/{{ detail.submited_by_name }}
              </p>
              <p style="font-size: 12px; margin: 0 0 0 15px">
                Tgl. Terima :
                {{ detail.received_at }}/{{ detail.received_by_name }}
              </p>
            </div>

            <div
              v-if="detail.status.id === 0"
              class="d-flex"
              style="position: absolute; left: 0; top: 5px"
            >
              <v-btn
                v-if="!isEdit"
                @click.prevent="editHeader()"
                type="button"
                text
                elevation="0"
                color="primary"
                class="white--text font-weight-bold"
                style="font-size: 12px"
                :loading="loading"
              >
                <v-icon small>mdi-pencil</v-icon>
                Edit Header
              </v-btn>

              <v-btn
                v-if="isEdit"
                type="submit"
                text
                outlined
                elevation="0"
                color="success"
                class="white--text font-weight-bold"
                style="font-size: 12px"
                :loading="loading"
              >
                <v-icon small>mdi-save</v-icon>
                Simpan
              </v-btn>
              <v-btn
                v-if="isEdit"
                @click="cancelHeader"
                text
                outlined
                type="button"
                elevation="0"
                color="error"
                class="white--text font-weight-bold"
                style="font-size: 12px; margin-left: 10px"
                :loading="loading"
              >
                Batal
              </v-btn>
            </div>
          </v-toolbar-title>
          <v-row style="padding: 15px; margin-top: 15px" no-gutters>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Project
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 6px">
                  <v-text-field
                    v-if="!isEdit"
                    outlined
                    dense
                    :value="detail.project.name"
                    disabled
                  />
                  <v-autocomplete
                    v-else
                    v-model="detail.project"
                    :items="dropdown.project"
                    item-text="name"
                    item-value="id"
                    return-object
                    outlined
                    dense
                    style="margin: 0"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    No. RAB
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 6px">
                  <v-text-field
                    outlined
                    dense
                    :value="detail.budget_no"
                    disabled
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nama RAB
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 6.5px">
                  <v-text-field
                    v-model="detail.name"
                    outlined
                    dense
                    :disabled="!isEdit"
                  />
                </v-col>
              </div>
            </v-col>

            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Perusahaan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 4px">
                  <v-text-field
                    outlined
                    dense
                    :value="detail.company.name"
                    disabled
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Penanggung Jawab
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 6px">
                  <v-text-field
                    outlined
                    dense
                    :value="detail.pic_employee.name"
                    disabled
                  />
                </v-col>
              </div>
            </v-col>
            <v-col v-if="detail.status.id === 5" cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Alasan Cancel
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 4px">
                  <v-textarea
                    :value="`[${detail.cancel_by_name}| ${detail.cancel_at}]\n${detail.cancel_reason}`"
                    no-resize
                    outlined
                    rows="2"
                    dense
                    disabled
                  />
                </v-col>
              </div>
            </v-col>
            <v-col v-if="detail.status.id === 6" cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Alasan Reject
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 4px">
                  <v-textarea
                    :value="`${detail.reject_reason}`"
                    no-resize
                    outlined
                    rows="2"
                    dense
                    disabled
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="margin-top: 10px; padding: 0 10px">
              <div class="d-flex" style="margin-top: 10px">
                <v-btn
                  v-if="detail.status.id === 0"
                  elevation="1"
                  color="primary"
                  type="button"
                  small
                  class="white--text font-weight-bold mb-2 mr-3"
                  :loading="loading"
                  @click="openRabItemDialog('add')"
                  :disabled="isEdit"
                >
                  + Tambah Barang
                </v-btn>
                <div v-if="detail.status.id === 3">
                  <v-btn
                    v-if="selectedData.length > 0"
                    elevation="1"
                    color="primary"
                    type="button"
                    small
                    class="white--text font-weight-bold"
                    style="margin: 0 0 3px 10px"
                    @click="createFPB"
                    :loading="loading"
                    :disabled="isEdit"
                  >
                    + Buat FPB
                  </v-btn>
                </div>
              </div>
              <v-data-table
                v-model="selectedData"
                @click:row="rowClick"
                :search="searchItem"
                :headers="headers"
                :items="detail.budget_plan_detail"
                :items-per-page="10"
                :loading="loading"
                :show-select="
                  getUserProfile.level.find(({ id }) => id === '41') ===
                    undefined && detail.status.id === 3
                "
                mobile-breakpoint="0"
                class="elevation-1"
                style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                item-key="id"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="searchItem"
                    label="Cari barang"
                    class="mx-4"
                    style="width: 310px"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.no`]="{ index }">
                  <td>
                    {{ index + 1 }}
                  </td>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div style="width: 50px" class="d-flex">
                    <v-btn
                      @click.stop="deleteItem(item)"
                      type="button"
                      rounded
                      x-small
                      icon
                      elevation="0"
                      color="red"
                      class="white--text font-weight-bold ma-2"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                    <!-- <v-btn
                      type="button"
                      rounded
                      x-small
                      icon
                      elevation="0"
                      color="primary"
                      class="white--text font-weight-bold ma-2"
                    >
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn> -->
                  </div>
                </template>
                <template v-slot:[`item.product.code`]="{ item }">
                  <div style="width: 60px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.product.code }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.product.name`]="{ item }">
                  <div style="width: 150px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.product.name }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.category`]="{ item }">
                  <div style="width: 70px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.category !== null ? item.category.name : '' }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <div style="width: 70px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">{{ item.type }}</p>
                  </div>
                </template>
                <template v-slot:[`item.dimension`]="{ item }">
                  <div style="width: 70px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.dimension }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.photo`]="{ item }">
                  <div style="width: 150px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">{{ item.photo }}</p>
                  </div>
                </template>
                <template v-slot:[`item.material`]="{ item }">
                  <div style="width: 70px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.material }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.merk`]="{ item }">
                  <div style="width: 70px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">{{ item.merk }}</p>
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <div style="width: 150px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.description }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.vendor_recomendation`]="{ item }">
                  <div style="width: 150px" class="d-flex">
                    <p
                      v-if="item.product.code !== null"
                      style="margin: 0; font-size: 12px"
                    >
                      {{ item.vendor.name }}
                    </p>
                    <p v-else style="margin: 0; font-size: 12px">
                      {{ item.vendor_recomendation }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.estimate_date_use`]="{ item }">
                  <div style="width: 70px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.estimate_date_use }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                  <div style="width: 50px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">{{ item.qty }}</p>
                  </div>
                </template>
                <template v-slot:[`item.qty_remain`]="{ item }">
                  <div style="width: 50px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      {{ item.qty_remain }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.budget_price_per_unit`]="{ item }">
                  <div style="width: 100px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      Rp.{{ priceConvert(item.budget_price_per_unit) }}
                    </p>
                  </div>
                </template>
                <template v-slot:[`item.budget_price_total`]="{ item }">
                  <div style="width: 100px" class="d-flex">
                    <p style="margin: 0; font-size: 12px">
                      Rp.{{ priceConvert(item.budget_price_total) }}
                    </p>
                  </div>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <div class="items-total-div-1">
                <div class="items-total-div-2">
                  <v-card elevation="1" class="items-total-box">
                    <p style="margin-right: auto; font-weight: bold">
                      TOTAL PENGAJUAN
                    </p>
                    <div>
                      <p>Total Qty<span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ detail.total_plan_qty }}
                      </p>
                    </div>
                    <div>
                      <p>Total Harga <span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.total_budget_plan) }}
                      </p>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-col>

            <v-col cols="12" style="padding: 10px">
              <div
                style="
                  color: #4e4e4e;
                  margin-left: 5px;
                  font-weight: bold;
                  margin-bottom: 10px;
                  border-bottom: 1px solid #a1a1a1;
                "
              >
                FPB LIST
              </div>
              <v-data-table
                :headers="fpbHeaders"
                @click:row="openDetailFpb"
                :items="fpbResult"
                :items-per-page="10"
                hide-default-footer
                mobile-breakpoint="0"
                class="elevation-1 mx-auto"
                style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
              >
              </v-data-table>
            </v-col>
            <v-col cols="12" style="padding: 10px; margin-top: 20px">
              <!-- :loading="matrix.length === 0" -->
              <div
                style="
                  color: #4e4e4e;
                  margin-left: 5px;
                  font-weight: bold;
                  margin-bottom: 10px;
                  border-bottom: 1px solid #a1a1a1;
                "
              >
                APPROVAL MATRIX
              </div>
              <v-data-table
                mobile-breakpoint="0"
                :headers="approvalHeaders"
                :items-per-page="10"
                class="elevation-1 mx-auto"
                hide-default-footer
              >
                <!-- <template v-slot:[`item.document_no`]="{ item }">
                <div style="width:140px; fontSize:12px;">
                  {{ item.document_no }}
                </div>
              </template>
              <template v-slot:[`item.table_name`]="{ item }">
                <div style="width:140px; fontSize:12px;">
                  {{ item.table_name }}
                </div>
              </template>
              <template v-slot:[`item.approver_user`]="{ item }">
                <div style="cursor:pointer;" v-if="item">
                  <v-chip
                    label
                    outlined
                    small
                    style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                    v-for="(item, index) in item.approver_user"
                    :key="index"
                  >
                    {{ item.user !== null ? item.user.name : '-' }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.approved`]="{ item }">
                <div
                  v-for="(items, index) in item.approver_user"
                  :key="index"
                  style="width:140px;"
                >
                  <v-chip
                    v-if="items.status === 1"
                    label
                    outlined
                    small
                    style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                  >
                    {{ item.user !== null ? items.user.name : '-' }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.rejected`]="{ item }">
                <div
                  v-for="(items, index) in item.approver_user"
                  :key="index"
                  style="width:140px;"
                >
                  <v-chip
                    v-if="items.status === -1"
                    label
                    outlined
                    small
                    style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                  >
                    {{ item.user !== null ? items.user.name : '-' }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div style="width:140px; fontSize:12px;">
                  {{ item.created_at }}
                </div>
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                <div
                  v-for="(items, index) in item.approver_user"
                  :key="index"
                  style="width:140px; fontSize:12px;"
                >
                  <span v-if="items.status === 1">
                    {{ items.updated_at !== null ? items.updated_at : '-' }}
                  </span>
                  <br v-if="index > 0" />
                </div>
              </template> -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-container>

    <v-dialog v-model="rabDialog" width="800" persistent>
      <!-- :readonly="detail.status.id !== 0" -->
      <!-- :disabled="
        getUserProfile.level.find(({ id }) => id === '41') !== undefined
      " -->
      <v-form
        v-if="detail !== null"
        @submit.prevent="submitItem"
        lazy-validation
        ref="entryFormItem"
        style="position: relative"
        :disabled="detail.status.id !== 0"
      >
        <v-card>
          <v-card-title class="text-h5 white lighten-2">
            <p style="margin: auto">TAMBAH/EDIT BARANG</p>
          </v-card-title>

          <v-card-text v-if="formItem !== null" class="d-flex">
            <div style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-row>
                  <v-col
                    cols="12"
                    style="
                      padding: 0 0 0 15px;
                      display: flex;
                      justify-content: start;
                      align-items: center;
                      margin-bottom: 10px;
                    "
                  >
                    <v-col
                      cols="7"
                      style="
                        padding: 0;
                        justify-content: flex-start;
                        align-items: center;
                        display: flex;
                      "
                    >
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Cari barang dari katalog ?
                      </p>
                      <v-checkbox
                        v-model="formItem.search_from_ecatalogue"
                        :false-value="false"
                        :true-value="true"
                        style="margin: 0 0 0 10px; height: 25px; padding: 0"
                        :disabled="formItem.act === 'update'"
                      ></v-checkbox>
                    </v-col>
                  </v-col>
                  <v-col cols="8">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Nama Barang
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <div v-if="formItem.search_from_ecatalogue">
                            <v-autocomplete
                              v-if="formItem.act === 'add'"
                              v-model="selectedItem"
                              :items="catalogueItems"
                              :filter="customFilter"
                              clearable
                              outlined
                              item-text="product.name"
                              item-value="id"
                              hide-details
                              hide-no-data
                              return-object
                              label=""
                              :loading="loading"
                              :search-input.sync="searchCatalogue"
                              style="margin: 0"
                              dense
                              @keydown.enter.prevent="
                                enterSearch(searchCatalogue)
                              "
                            >
                              <template v-slot:prepend-item>
                                <div
                                  style="
                                    padding: 0 15px;
                                    position: sticky;
                                    top: 0;
                                    z-index: 2;
                                  "
                                >
                                  <div
                                    style="
                                      padding: 0 25px;
                                      height: 25px;
                                      background: black;
                                      color: white;
                                      border: 2px solid black;
                                      display: flex;
                                      justify-content: flex-start;
                                      align-items: center;
                                    "
                                  >
                                    <v-toolbar-title
                                      style="
                                        text-align: left;
                                        font-size: 14px;
                                        padding: 0;
                                        width: 200px;
                                      "
                                    >
                                      Item Name
                                    </v-toolbar-title>
                                    <v-divider
                                      class="mx-3"
                                      inset
                                      vertical
                                    ></v-divider>
                                    <v-toolbar-title
                                      style="
                                        text-align: left;
                                        font-size: 14px;
                                        padding: 0;
                                        width: 120px;
                                      "
                                    >
                                      Item Code
                                    </v-toolbar-title>

                                    <v-divider
                                      class="mx-3"
                                      inset
                                      vertical
                                    ></v-divider>

                                    <v-toolbar-title
                                      style="
                                        text-align: left;
                                        font-size: 14px;
                                        padding: 0;
                                        width: 200px;
                                      "
                                    >
                                      Supplier Name
                                    </v-toolbar-title>

                                    <v-divider
                                      class="mx-3"
                                      inset
                                      vertical
                                    ></v-divider>

                                    <v-toolbar-title
                                      style="
                                        text-align: left;
                                        font-size: 14px;
                                        padding: 0;
                                        width: 120px;
                                      "
                                    >
                                      Supplier code
                                    </v-toolbar-title>
                                  </div>
                                </div>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-card
                                  rounded
                                  color="transparent"
                                  style="
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    width: 800px;
                                    padding: 7px;
                                  "
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-toolbar-title
                                        v-bind="attrs"
                                        v-on="on"
                                        style="
                                          text-align: left;
                                          font-size: 14px;
                                          padding: 0;
                                          width: 200px;
                                        "
                                      >
                                        {{ item.product.name }}
                                      </v-toolbar-title>
                                    </template>
                                    <span>{{ item.product.name }}</span>
                                  </v-tooltip>
                                  <v-divider
                                    class="mx-3"
                                    inset
                                    vertical
                                  ></v-divider>
                                  <v-toolbar-title
                                    style="
                                      text-align: left;
                                      font-size: 14px;
                                      padding: 0;
                                      width: 120px;
                                    "
                                  >
                                    #{{ item.product.code }}
                                  </v-toolbar-title>

                                  <v-divider
                                    class="mx-3"
                                    inset
                                    vertical
                                  ></v-divider>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-toolbar-title
                                        v-bind="attrs"
                                        v-on="on"
                                        style="
                                          text-align: left;
                                          font-size: 14px;
                                          padding: 0;
                                          width: 200px;
                                        "
                                      >
                                        {{
                                          item.vendor.name !== null
                                            ? item.vendor.name
                                            : ''
                                        }}
                                      </v-toolbar-title>
                                    </template>
                                    <span>{{
                                      item.vendor.name !== null
                                        ? item.vendor.name
                                        : ''
                                    }}</span>
                                  </v-tooltip>

                                  <v-divider
                                    class="mx-3"
                                    inset
                                    vertical
                                  ></v-divider>

                                  <v-toolbar-title
                                    style="
                                      text-align: left;
                                      font-size: 14px;
                                      padding: 0;
                                      width: 120px;
                                    "
                                  >
                                    #{{
                                      item.vendor.code !== null
                                        ? item.vendor.code
                                        : ''
                                    }}
                                  </v-toolbar-title>
                                </v-card>
                              </template>
                            </v-autocomplete>
                            <v-text-field
                              v-else
                              v-model="formItem.product.name"
                              outlined
                              readonly
                              dense
                            ></v-text-field>
                          </div>
                          <v-text-field
                            v-else
                            v-model="formItem.product_name"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Kode Barang
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height:41px;">
                          <v-text-field outlined dense></v-text-field>
                        </div> </v-col
                    ></v-row>
                  </v-col> -->
                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Kategori
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div
                          v-if="formItem.act === 'update'"
                          style="height: 41px"
                        >
                          <v-text-field
                            v-if="formItem.product.id !== null"
                            v-model="formItem.category.name"
                            outlined
                            dense
                            readonly
                          ></v-text-field>
                          <v-autocomplete
                            v-else
                            v-model="formItem.category"
                            :items="dropdown.category"
                            item-text="name"
                            item-value="id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-autocomplete>
                        </div>

                        <div v-else style="height: 41px">
                          <v-autocomplete
                            v-model="formItem.category"
                            :items="dropdown.category"
                            item-text="name"
                            item-value="id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-autocomplete>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Material
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.material"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Dimensi
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.dimension"
                            outlined
                            dense
                          ></v-text-field>
                        </div> </v-col
                    ></v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Merk
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.merk"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Foto/Link
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.photo"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Tipe
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.type"
                            outlined
                            dense
                          ></v-text-field>
                        </div> </v-col
                    ></v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Kegunaan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-textarea
                            v-model="formItem.description"
                            rows="1"
                            outlined
                            dense
                          ></v-textarea>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="3">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          qty
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.qty"
                            type="number"
                            min="0"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Satuan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-autocomplete
                            v-model="formItem.uom"
                            :items="dropdown.uom"
                            item-text="name"
                            item-value="id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-autocomplete>
                        </div> </v-col
                    ></v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Harga Satuan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.budget_price_per_unit"
                            type="double"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Total Harga
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-if="formItem.act === 'update'"
                            :value="itemEdit.budget_price_total"
                            type="double"
                            readonly
                            outlined
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-else
                            :value="
                              formItem.budget_price_per_unit * formItem.qty
                            "
                            readonly
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Tgl. Pemakaian
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.estimate_date_use"
                            dense
                            type="date"
                            name="startdate"
                            step="1"
                            outlined
                            clearable
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Rekomendasi Vendor
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div style="height: 41px">
                          <v-text-field
                            v-model="formItem.vendor_recomendation"
                            dense
                            outlined
                            clearable
                          />
                          <!-- <v-autocomplete
                            item-text="name"
                            item-value="id"
                            return-id
                            outlined
                            dense
                            clearable
                          ></v-autocomplete> -->
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Keterangan Tambahan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <div>
                          <v-textarea outlined dense></v-textarea>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
              </div>
            </div>
          </v-card-text>

          <!-- <v-card-actions> -->
          <div style="padding: 10px 15px">
            <v-btn
              v-if="detail.status.id === 0"
              color="primary"
              style="width: 100%; margin: 10px 0"
              type="submit"
              :loading="loading"
            >
              simpan
            </v-btn>
            <v-btn
              color="error"
              style="width: 100%"
              type="button"
              :loading="loading"
              @click="closeRabItemDialog"
            >
              batal
            </v-btn>
          </div>
          <!-- </v-card-actions> -->
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="createFpbDialog" full-screen persistent>
      <v-form ref="entryFormFpb" @submit.prevent="submitFPB">
        <v-card style="padding: 10px">
          <v-card-title class="text-h5 grey lighten-2">
            BUAT FPB DARI ITEM DIBAWAH INI
          </v-card-title>

          <v-card-text
            class="d-flex justify-center align-center"
            style="padding: 10px 20px"
          >
            <v-row no-gutters>
              <v-col cols="3" style="padding: 0; margin-top: 10px">
                <div style="padding: 0 10px">
                  <v-select
                    label="Kategori Barang"
                    style="
                      position: relative;
                      top: 15px;
                      margin: 0 15px 0 0;
                      font-size: 12px;
                      width: 220px;
                    "
                    v-model="formFpb.category_item"
                    :items="[
                      { id: 1, name: 'Raw Material' },
                      { id: 2, name: 'Factory Supply' },
                      { id: 3, name: 'Office Supply' },
                      { id: 4, name: 'Sparepart' },
                      { id: 5, name: 'Jasa' },
                      { id: 6, name: 'Maintenance Repair' },
                      { id: 7, name: 'Investment / Asset' }
                    ]"
                    item-text="name"
                    item-value="id"
                    return-id
                    outlined
                    dense
                    :rules="rules.itemRules"
                    clearable
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="3" style="padding: 0; margin-top: 10px">
                <div style="padding: 0 10px">
                  <v-select
                    label="Kategori PR"
                    style="
                      position: relative;
                      top: 15px;
                      margin: 0 15px 0 0;
                      font-size: 12px;
                      width: 220px;
                    "
                    v-model="formFpb.category_pr"
                    :items="[
                      { id: 'bahan_baku', name: 'Bahan Baku' },
                      { id: 'bahan_pendukung', name: 'Bahan Pendukung' },
                      { id: 'asset', name: 'Asset' },
                      { id: 'barang_khusus', name: 'Barang Khusus' },
                      { id: 'operasional', name: 'Operasional' },
                      { id: 'project', name: 'Project' },
                      { id: 'lain', name: 'Lain-lain' }
                    ]"
                    item-text="name"
                    item-value="id"
                    return-id
                    outlined
                    dense
                    :rules="rules.prRules"
                    clearable
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0; margin-top: 10px">
                <!-- <div style="padding: 0 10px"> -->
                <v-data-table
                  :headers="selectedItemHeader"
                  :items="selectedData"
                  :items-per-page="10"
                  :loading="loading"
                  mobile-breakpoint="0"
                  style="border: 1px solid rgba(0, 0, 0, 0.2)"
                >
                  <template v-slot:[`item.no`]="{ index }">
                    <td>
                      {{ index + 1 }}
                    </td>
                  </template>
                  <template v-slot:[`item.product.code`]="{ item }">
                    <div style="width: 60px" class="d-flex">
                      <p style="margin: 0; font-size: 12px">
                        {{ item.product.code }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:[`item.product.name`]="{ item }">
                    <div style="width: 150px" class="d-flex">
                      <p style="margin: 0; font-size: 12px">
                        {{ item.product.name }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:[`item.estimate_date_use`]="{ item }">
                    <div style="width: 70px" class="d-flex">
                      <p style="margin: 0; font-size: 12px">
                        {{ item.estimate_date_use }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:[`item.qty_remain`]="{ item }">
                    <div style="width: 50px" class="d-flex">
                      <p style="margin: 0; font-size: 12px">
                        {{ item.qty_remain }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:[`item.qty_fpb`]="{ item }">
                    <div style="width: 80px; height: 45px" class="d-flex">
                      <v-text-field
                        v-model="item.qty_fpb"
                        autofocus
                        dense
                        small
                        type="number"
                        min="1"
                        style="font-size: 12px"
                        single-line
                        :rules="rules.qtyRules"
                      ></v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.budget_price_per_unit`]="{ item }">
                    <div style="width: 100px" class="d-flex">
                      <p style="margin: 0; font-size: 12px">
                        Rp.{{ priceConvert(item.budget_price_per_unit) }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:[`item.budget_price_total`]="{ item }">
                    <div style="width: 100px" class="d-flex">
                      <p style="margin: 0; font-size: 12px">
                        Rp.{{ priceConvert(item.budget_price_total) }}
                      </p>
                    </div>
                  </template>
                </v-data-table>
                <!-- </div> -->
              </v-col>
              <!-- <v-col md="12" style="padding: 0; margin-top:20px;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Penanggung Jawab
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      :items="dropdownEmployee"
                      :search-input.sync="searchEmployee"
                      :rules="rules.rabEmployeeRules"
                      v-model="formRab.employee"
                      dense
                      clearable
                      outlined
                      item-text="name"
                      item-id="id"
                      hide-details
                      hide-no-data
                      return-object
                      style="height: 40px;"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col> -->
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              type="reset"
              @click="closeFpbDialog"
              :loading="loading"
            >
              Tutup
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- <div
      v-else
      class="d-flex justify-center align-center"
      style="width:100%; height:80vh;position:relative;"
    >
      <v-btn
        type="button"
        rounded
        elevation="0"
        color="red"
        class="white--text font-weight-bold ma-2"
        style="font-size:12px; position:absolute; top: 10px; left:10px;"
        @click.prevent="close"
        :loading="loading"
        outlined
      >
        <v-icon small>mdi-chevron-left</v-icon>
        Kembali
      </v-btn>
      <p style="color: RGBA(0,0,0,0.5); font-size:24px; font-weight:bold;">
        DATA NOT FOUND
      </p>
    </div> -->
  </div>

  <div v-else class="d-flex justify-center align-center" style="height: 100%">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'rabdetail',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      proc: buildType.apiURL('proc'),
      build: process.env.VUE_APP_BUILD_TYPE,
      wWidth: window.innerWidth,
      dialog: false,
      cancelDialog: false,
      loading: false,
      detail: null,
      approvalHeaders: [
        { text: 'Sequence', value: 'sequence', align: 'left', width: '5%' },
        { text: 'Doc. No', value: 'document_no', align: 'left', width: '10%' },
        { text: 'Model', value: 'table_name', align: 'center', width: '10%' },
        { text: 'User', value: 'approver_user', align: 'center', width: '20%' },
        {
          text: 'Min. Approver',
          value: 'min_approver',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Approved by',
          value: 'approved',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Rejected by',
          value: 'rejected',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center',
          width: '15%'
        },
        {
          text: 'Update at',
          value: 'updated_at',
          align: 'center',
          width: '15%'
        }
      ],
      fpbHeaders: [
        {
          text: 'No. Request',
          value: 'request_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Company',
          value: 'company.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Category',
          value: 'category_item.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Project',
          value: 'project.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jumlah budget',
          value: 'total_price',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. buat',
          value: 'created_at',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. submit',
          value: 'requested_at',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status.name',
          align: 'left',
          sortable: false
        }
      ],
      formHeader: null,
      formItem: null,
      rabResult: [],
      fpbResult: [],
      rabDialog: false,
      isEdit: false,

      dropdown: {
        company: [],
        department: [],
        project: [],
        category: [],
        uom: []
      },
      rules: {
        company: [],
        qtyRules: [],
        prRules: [],
        itemRules: []
      },
      selectedData: [],
      selectedItem: null,
      itemEdit: null,
      catalogueItems: [],
      searchCatalogue: null,
      selectCatalogue: null,
      searchItem: '',
      createFpbDialog: false,

      selectedItemHeader: [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kode Barang',
          value: 'product.code',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nama Barang',
          value: 'product.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Qty Sisa',
          value: 'qty_remain',
          align: 'left',
          sortable: false
        },
        {
          text: 'Qty FPB',
          value: 'qty_fpb',
          align: 'left',
          sortable: false
        },
        {
          text: 'Satuan',
          value: 'uom.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Harga Satuan',
          value: 'budget_price_per_unit',
          align: 'left',
          sortable: false
        },
        {
          text: 'Budget Total',
          value: 'budget_price_total',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. Pakai',
          value: 'estimate_date_use',
          align: 'left',
          sortable: false
        }
      ],
      formFpb: {
        category_item: null,
        category_pr: null
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  watch: {
    searchCatalogue() {
      if (this.searchCatalogue === null || this.searchCatalogue === '') {
        this.catalogueItems = []
      }
    },
    selectedItem(val) {
      console.log('selectedItem >>>', val)
    }
  },
  computed: {
    ...mapGetters(['getUserProfile']),
    headers() {
      const arrHeaders = [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kode Barang',
          value: 'product.code',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nama Barang',
          value: 'product.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kategori',
          value: 'category',
          align: 'left',
          sortable: false
        },
        {
          text: 'Dimensi',
          value: 'dimension',
          align: 'left',
          sortable: false
        },
        {
          text: 'Merk',
          value: 'merk',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tipe',
          value: 'type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Material',
          value: 'material',
          align: 'left',
          sortable: false
        },
        {
          text: 'Foto',
          value: 'photo',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kegunaan',
          value: 'description',
          align: 'left',
          sortable: false
        },
        {
          text: 'Qty',
          value: 'qty',
          align: 'left',
          sortable: false
        },
        {
          text: 'Sisa Qty',
          value: 'qty_remain',
          align: 'left',
          sortable: false
        },
        {
          text: 'Satuan',
          value: 'uom.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Harga Satuan',
          value: 'budget_price_per_unit',
          align: 'left',
          sortable: false
        },
        {
          text: 'Budget Total',
          value: 'budget_price_total',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. Pakai',
          value: 'estimate_date_use',
          align: 'left',
          sortable: false
        },
        {
          text: 'Rek. Vendor',
          value: 'vendor_recomendation',
          align: 'left',
          sortable: false
        },
        {
          text: 'Action',
          value: 'action',
          align: 'left'
          // sortable: false,
        }
      ]
      if (this.detail !== null) {
        if (this.detail.status.id !== 0) {
          arrHeaders.pop()
        }
      }
      return arrHeaders
    }
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.getDetail()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    // ...mapMutations(['setRabDetail']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    enterSearch(val) {
      val && val !== this.selectCatalogue && this.querySelections(val)
    },
    querySelections(v) {
      const getCatalogue = () => {
        return new Promise((resolve) => {
          axios
            .get(
              // `${this.proc}vendor/catalogue/list?keyword=${v}&offset=0&limit=10&vendor_id=&category_id=`
              `${this.proc}vendor/catalogue/dropdown_product?keyword=${v}`
            )
            .then((res) => {
              console.log('catalogue>>>', res)
              resolve(res.data.data)
            })
        })
      }

      // this.loading = true
      getCatalogue().then((res) => {
        if (res !== undefined) {
          this.catalogueItems = res
        } else {
          this.catalogueItems = []
        }
        // this.loading = false
      })
    },
    rowClick(pValue) {
      this.itemEdit = pValue
      console.log('item>>>>', pValue)
      this.initDropdown()
      setTimeout(() => {
        this.openRabItemDialog('update')
        // document.getElementById('fpbFormEdit').click()
      }, 500)
    },
    async getDetail() {
      this.loading = true
      this.detail = null
      const url = `${this.proc}rab/detail/${this.$route.params.id}`
      await axios
        .get(url)
        .then((res) => {
          console.log('detail>>>', res)
          if (res.data.status_code !== '-99') {
            // res.data.data.
            this.detail = res.data.data
            this.getListFpb()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    async getListFpb() {
      this.fpbResult = []
      const url = `${this.proc}fpb/list?keyword=&offset=0&limit=10&company_id=${this.detail.company.id}&budget_plan_id=${this.$route.params.id}`
      await axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.fpbResult = res.data.data
            console.log('fpbResult>>>', res)
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog('error', err.msg, false)
        })
    },
    openDetailFpb(pValue, pSlot) {
      // this.$store.commit('setFpbId', pValue.id)
      const arrItem = {
        list: this.fpbResult
        // index: pSlot.index
      }
      this.$store.commit('setFpbList', arrItem)
      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/fpb-list/fpb/${pValue.id}?idx=${pSlot.index}`
        )
        // this.$router.push('/e-catalogue/fpb-list/fpb')
      }, 200)
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    close() {
      if (this.detail !== null) {
        this.$refs.entryForm.reset()
      }
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/e-catalogue/rab/submission') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/e-catalogue/rab')
        }
      } else {
        this.$router.push('/e-catalogue/rab')
      }
    },
    editHeader() {
      this.formHeader = {
        name: this.detail.name,
        project: this.detail.project,
        company: this.detail.company,
        pic_employee: this.detail.pic_employee
      }
      this.dropdownProject()
      this.isEdit = true
      // this.setRabDetail(this.detail)
      // setTimeout(() => {
      //   this.$router.push('/e-catalogue/rab/submission')
      // }, 300)
    },
    cancelHeader() {
      this.dropdown.project = []
      this.detail.project = this.formHeader.project
      setTimeout(() => {
        this.isEdit = false
      }, 100)
    },

    submitHeader() {
      const self = this
      // this.rules.nameRules = [v => !!v || 'Nama project diperlukan']
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const project = this.detail.project !== null
      const form = {
        act: 'update',
        name: this.detail.name,
        project_id: project ? this.detail.project.id : null,
        project_name: project ? this.detail.project.name : null,
        project_code: project ? this.detail.project.code : null
        // company_id: this.detail.company.id,
        // company_name: this.detail.company.name,
        // pic_employee_id: this.detail.pic_employee.id,
        // pic_employee_nik: this.detail.pic_employee.nik,
        // pic_employee_name: this.detail.pic_employee.name
      }
      if (form.act === 'update') {
        Object.assign(form, {
          id: this.detail.id
        })
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.proc}rab/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.formHeader = null
            this.dropdown.project = []
            this.isEdit = false
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    submitRAB() {
      this.showMsgDialog3(
        'warning',
        'Pastikan semua data sudah terisi dengan benar',
        true,
        'Submit Sekarang',
        'Batalkan'
      ).then(async (res) => {
        if (res.isConfirmed) {
          const xFlag = true

          if (xFlag) {
            this.loading = true
            const form = {
              id: this.$route.params.id
            }
            axios
              .post(`${this.proc}rab/submit`, form)
              .then((res) => {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.loading = false
                this.getDetail()
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
              })
          }
        }
      })
    },
    confirmRAB() {
      this.showMsgDialog(
        'question',
        'Setujui RAB ini sekarang ?',
        true
        // inputType,
        // inputAtt
      ).then((res) => {
        if (res.isConfirmed) {
          const document_id = this.$route.params.id
          this.loading = true
          axios
            .post(`${this.proc}rab/confirm`, {
              document_id: document_id
            })
            .then((res) => {
              this.showMsgDialog('success', res.data.status_msg, false)
              this.loading = false
              this.getDetail()
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        }
      })
    },
    rejectRAB() {
      this.showMsgDialog2('question', 'Jangan setujui RAB ini ?', true).then(
        (res) => {
          if (res.isConfirmed) {
            this.loading = true

            const date = new Date()
            const local = date.toLocaleString('id')
            const updateAt = `[${local}]\n${res.value}`
            axios
              .post(`${this.proc}rab/reject`, {
                document_id: this.$route.params.id,
                reject_reason: updateAt
              })
              .then((res) => {
                if (res.data.status_code === '00') {
                  this.showMsgDialog('success', res.data.status_msg, false)
                } else {
                  this.showMsgDialog('error', res.data.status_msg, false)
                }
                this.loading = false
                this.getDetail()
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
              })
          }
        }
      )
    },
    takeRAB() {
      this.showMsgDialog('question', 'Proses RAB ini ?', true).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          axios
            .post(`${this.proc}rab/take`, {
              id: this.$route.params.id
            })
            .then((res) => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', 'take success', false)
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
              this.loading = false
              this.getDetail()
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        }
      })
    },
    setToDraftRAB() {
      this.showMsgDialog('question', 'Kembalikan ke draft ?', true).then(
        (res) => {
          if (res.isConfirmed) {
            this.loading = true
            axios
              .post(`${this.proc}rab/set_to_draft`, {
                id: this.$route.params.id
              })
              .then((res) => {
                if (res.data.status_code === '00') {
                  this.showMsgDialog('success', 'set to draft success', false)
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
                this.loading = false
                this.getDetail()
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
              })
          }
        }
      )
    },
    doneRAB() {
      this.showMsgDialog(
        'question',
        'Sudah selesai ?',
        true
        // inputType,
        // inputAtt
      ).then((res) => {
        if (res.isConfirmed) {
          const id = this.$route.params.id
          this.loading = true
          axios
            .post(`${this.proc}rab/done`, {
              id: id
            })
            .then((res) => {
              this.showMsgDialog('success', res.data.status_msg, false)
              this.loading = false
              this.getDetail()
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        }
      })
    },
    cancelRAB() {
      this.showMsgDialog2(
        'question',
        'Anda yakin ingin membatalkan RAB ini ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true

          // const date = new Date()
          // const local = date.toLocaleString('id')
          const updateAt = res.value
          axios
            .post(`${this.proc}rab/cancel`, {
              id: this.$route.params.id,
              cancel_reason: updateAt
            })
            .then((res) => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                this.showMsgDialog('error', res.data.status_msg, false)
              }
              this.loading = false
              this.getDetail()
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        }
      })
    },
    dropdownProject() {
      this.dropdown.project = []
      let url = `${this.proc}globalmaster/project/dropdown?keyword=`

      const filter = []
      // &status=${this.paramAPI.status}
      if (this.detail.company !== null) {
        filter.push(`{"company_id":${this.detail.company.id}}`)
      }
      url = url + `&filter=[${filter}]`
      axios
        .get(url)
        .then((res) => {
          this.dropdown.project = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    dropdownUnit() {
      this.dropdown.uom = []
      const url = `${this.proc}master/unit/drop_down?keyword=`
      axios
        .get(url)
        .then((res) => {
          this.dropdown.uom = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    dropdownCategory() {
      this.dropdown.category = []
      const url = `${this.proc}master/product_category/drop_down?keyword=`
      axios
        .get(url)
        .then((res) => {
          this.dropdown.category = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitItem() {
      const self = this
      // this.rules.nameRules = [v => !!v || 'Nama project diperlukan']
      // this.rules.codeRules = [v => !!v || 'Kode project diperlukan']
      // this.rules.companyRules = [v => !!v || 'Unit/plant diperlukan']
      // this.rules.picRules = [v => !!v || 'PIC diperlukan']

      setTimeout(function () {
        if (self.$refs.entryFormItem.validate()) {
          self.createNewItemForm()
        }
      })
    },
    createNewItemForm() {
      let product = null
      let vendor = null

      console.log('Item', this.formItem)
      if (this.formItem.search_from_ecatalogue) {
        if (this.formItem.act === 'add') {
          if (this.selectedItem !== null) {
            product = this.selectedItem.product
            vendor = this.selectedItem.vendor
          }
        } else {
          product = this.formItem.product
          vendor = this.formItem.vendor
        }
      } else {
        if (this.formItem.product !== null) {
          product = this.formItem.product
        }
      }
      const form = {
        act: this.formItem.act,

        request_id: this.$route.params.id,
        product_id: product !== null ? product.id : null,
        product_name:
          product !== null ? product.name : this.formItem.product_name,
        product_code: product !== null ? product.code : null,
        category_id:
          this.formItem.category !== null ? this.formItem.category.id : null,
        category_name:
          this.formItem.category !== null ? this.formItem.category.name : null,
        qty: this.formItem.qty,
        uom_id: this.formItem.uom !== null ? this.formItem.uom.id : null,
        uom_name: this.formItem.uom !== null ? this.formItem.uom.name : null,
        description: this.formItem.description,
        type: this.formItem.type,
        merk: this.formItem.merk,
        dimension: this.formItem.dimension,
        material: this.formItem.material,
        photo: this.formItem.photo,
        budget_price_per_unit: this.formItem.budget_price_per_unit,
        estimate_date_use: this.formItem.estimate_date_use,
        vendor_id: vendor !== null ? vendor.id : null,
        vendor_name: vendor !== null ? vendor.name : null,
        vendor_code: vendor !== null ? vendor.code : null,
        vendor_recomendation: this.formItem.vendor_recomendation
      }
      if (form.act === 'update') {
        Object.assign(form, {
          id: this.itemEdit.id
        })
      }
      console.log(form)
      this.saveDetailItem(form)
    },
    async saveDetailItem(form) {
      this.loading = true
      await axios
        .post(`${this.proc}rab/save_detail`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.closeRabItemDialog()
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async deleteItem(v) {
      console.log('Delete>>>>', v)
      this.loading = true
      await axios
        .delete(`${this.proc}rab/delete_detail/${v.id}`)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    initDropdown() {
      this.dropdownUnit()
      this.dropdownCategory()
    },
    openRabItemDialog(act) {
      this.setFormItem(act)
      this.initDropdown()
      setTimeout(() => {
        this.rabDialog = true
      }, 300)
    },
    closeRabItemDialog() {
      this.rabDialog = false
      setTimeout(() => {
        this.itemEdit = null
        this.formItem = null
        this.dropdown.uom = []
        this.dropdown.category = []
      }, 300)
    },
    setFormItem(act) {
      const form = {
        act: act,
        search_from_ecatalogue: false,
        product: null,
        product_name: '',
        category: null,
        qty: 0,
        uom: null,
        description: '',
        type: '',
        merk: '',
        dimension: '',
        material: '',
        photo: '',
        budget_price_per_unit: 0,
        estimate_date_use: null,
        vendor: null,
        vendor_recomendation: null
      }
      if (form.act === 'update') {
        if (this.itemEdit.product.id !== null) {
          this.querySelections(this.itemEdit.product.name)
          form.search_from_ecatalogue = true
        }
        form.product = this.itemEdit.product
        form.product_name = this.itemEdit.product.name
        // form.product.code = this.itemEdit.product.code
        // form.product.name = this.itemEdit.product.name
        form.vendor = this.itemEdit.vendor
        // form.vendor.code = this.itemEdit.vendor.code
        // form.vendor.name = this.itemEdit.vendor.name
        form.vendor_recomendation = this.itemEdit.vendor_recomendation
        form.category = this.itemEdit.category
        if (this.itemEdit.category !== null) {
          form.category.id = Number(this.itemEdit.category.id)
        }
        // form.category.name = this.itemEdit.category.name
        form.qty = this.itemEdit.qty
        form.uom = this.itemEdit.uom
        // form.uom.name = this.itemEdit.uom.name
        form.budget_price_per_unit = this.itemEdit.budget_price_per_unit
        form.description = this.itemEdit.description
        form.type = this.itemEdit.type
        form.material = this.itemEdit.material
        form.merk = this.itemEdit.merk
        form.dimension = this.itemEdit.dimension
        form.photo = this.itemEdit.photo
        form.estimate_date_use = this.convertDate(
          this.itemEdit.estimate_date_use
        )
      }

      this.formItem = form
    },

    createFPB() {
      console.log('Create>>>>>')
      setTimeout(() => {
        this.createFpbDialog = true
      }, 300)
    },
    closeFpbDialog() {
      this.selectedData = []
      setTimeout(() => {
        this.createFpbDialog = false
      }, 300)
    },
    submitFPB() {
      const self = this
      this.rules.qtyRules = [
        (v) => !!v || 'Harus diisi',
        (v) => v > 0 || 'Tidak boleh 0'
      ]
      this.rules.itemRules = [(v) => !!v || 'Harus diisi']
      this.rules.prRules = [(v) => !!v || 'Harus diisi']
      setTimeout(function () {
        if (self.$refs.entryFormFpb.validate()) {
          // self.createNewForm()
          console.log('this.selectedData >>>>', self.selectedData, self.detail)
          const form = {
            act: 'add_batch_in_item',
            reference_from_ecommerce: 0,
            budget_is_approved: 0,
            memo_special_request: 0,
            purchase_request_detail: [],
            category_item: self.formFpb.category_item,
            project_id: self.detail.project.id,
            budget_plan_id: self.$route.params.id,
            category_pr: self.formFpb.category_pr,
            company_id: self.detail.company.id,
            company_name: self.detail.company.name,
            company_code: self.alias(self.detail.company.id)
          }

          console.log('push detail item>>>>')
          for (let i = 0; i < self.selectedData.length; i++) {
            const obj = {
              product_id: self.selectedData[i].product.id,
              product_name: self.selectedData[i].product.name,
              product_code: self.selectedData[i].product.code,
              qty: Number(self.selectedData[i].qty_fpb),
              budget_price_per_unit: self.selectedData[i].budget_price_per_unit,
              vendor_id: self.selectedData[i].vendor.id,
              vendor_name: self.selectedData[i].vendor.name,
              vendor_code: self.selectedData[i].vendor.code,
              has_budget: 1,
              estimate_date_use: self.convertDate(
                self.selectedData[i].estimate_date_use
              ),
              description:
                self.selectedData[i].description === ''
                  ? self.selectedData[i].product.name
                  : self.selectedData[i].description,
              uom_id: self.selectedData[i].uom.id,
              uom_name: self.selectedData[i].uom.name
            }
            form.purchase_request_detail.push(obj)
          }
          console.log(form)
          self.saveFPB(form)
        }
      })
    },
    saveFPB(form) {
      this.loading = true
      axios
        .post(`${this.proc}fpb/save`, form)
        .then((res) => {
          if (res.data.status_code === '00') {
            const created_id = res.data.created_id

            this.$store.commit('setFpbId', created_id)

            this.$router.push('/e-catalogue/cart/fpb')
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    price(pItem) {
      if (pItem !== undefined) {
        if (pItem !== null) {
          return (
            'Rp. ' +
            pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          )
        }
      }
      return ''
    },
    statusColor(val) {
      switch (val) {
        case 0:
          return 'color: #black; background-color: #4e4e4e1f;'
        case 1:
          return 'color: rgb(138, 90, 0); background-color: rgba(255, 166, 0, 0.3);'
        case 2:
          return 'color: #black; background-color: #4e4e4e1f;'
        case 3:
          return 'color: #1976d2; background-color: #4e4e4e1f;'
        case 4:
          return 'color: green; background-color: #4e4e4e1f;'
        case 5:
          return 'color: red; background-color: #4e4e4e1f;'
        case 6:
          return 'color: red; background-color: #4e4e4e1f;'
        default:
          return 'black'
      }
    },
    convertDate(raw) {
      let res = ''
      if (raw === null || raw === undefined || raw === '') {
        res = ''
      } else {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        const split = local.split('/')
        const day = split[0].length === 1 ? '0' + split[0] : split[0]
        const month = split[1].length === 1 ? '0' + split[1] : split[1]
        const year = split[2]
        res = `${year}-${month}-${day}`
      }
      return res
    },
    customFilter(item, queryText, itemText) {
      if (item.vendor.name === null || item.vendor.code === null) {
        return (
          item.product.name
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.product.code
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase()) > -1
        )
      }
      return (
        item.product.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.product.code
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.vendor.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.vendor.code
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },

    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    showMsgDialog2(pModalType, pStatusMsg, pBtnCancel, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              additional +
              '<br/>' +
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: 'Submit',
            cancelButtonText: 'No',
            input: 'textarea',
            inputLabel: 'Tolong isi alasan pada kolom dibawah sebelum submit',
            inputPlaceholder: 'Tulis sesuatu disini...',
            inputAttributes: {
              'aria-label': 'Tulis sesuatu disini'
            }
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    showMsgDialog3(pModalType, pStatusMsg, pBtnCancel, yesBtn, noBtn, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: yesBtn,
            cancelButtonText: noBtn !== '' ? noBtn : 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    alias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
      }
    }
  }
}
</script>
<style lang="scss">
.actionButton {
  position: relative;
  display: flex;
  // width: 400px;
}
.approvalMatrix {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
}

.items-total-div-1 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  min-height: 150px;
  width: 100%;
  justify-content: flex-end;
  padding: 0 10px 0 0;
  .items-total-div-2 {
    // padding: 10px 0;
    width: '40%';
    .items-total-box {
      border: 1px solid #e0e0e0;
      padding: 20px 15px;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-size: 14px;
      div {
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        padding-top: 5px;
        display: flex;
        p {
          margin: 0;
          margin-right: 10px;
          min-width: 80px;
          position: relative;
          span {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  // .items-total-div-3 {
  //   padding: 0 10px;
  //   width: 60%;
  //   font-size: 14px;
  // }
}
@media (max-width: 766px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
  }
  .approvalMatrix {
    margin-bottom: 50px;
  }

  .items-total-div-1 {
    flex-direction: column-reverse;
    .items-total-div-2 {
      width: '100%';
      .items-total-box {
        width: 100%;
      }
    }

    // .items-total-div-3 {
    //   margin-top: 10px;
    //   width: 100%;
    // }
  }
}
</style>
